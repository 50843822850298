import { useEffect, useState } from "react"; 
import Slider from "react-slick"; 
import { getBirthdays } from '../Service/Api'; 

const Birthday = () => {
 const [data,setData] = useState([]);
     const [loading,setLoading] = useState(true);
     const [error,setError] = useState(null);
  
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

 useEffect(() => {
          const fetchData = async () =>{
                 try{
                    const birthdayData = await getBirthdays();
                    setData(birthdayData) ;  
                    console.log(birthdayData)
                 }catch(error){
                  setError(error);
                 }finally{
                  setLoading(false)
                 }
          }
          fetchData();
      },[]);

  const emptyArray = [
    {
      name: "Student Name",
      class: "VII A",
      date: "5 September",
      attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/topper.jpg",
    },
    {
      name: "Student Name",
      class: "VII A",
      date: "5 September",
      attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/topper.jpg",
    },
    {
      name: "Student Name",
      class: "VII A",
      date: "5 September",
      attachments: "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/topper.jpg",
    },
    // Other entries...
  ];

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow:3,
        }
      }, 
       {
        breakpoint: 1200,
        settings: {
          slidesToShow:2,
        }
      }, 
      {
        breakpoint: 992,
        settings: {
          slidesToShow:3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <> 
      <Slider {...settings}>
        {data && data.length > 0 ? (
          data.map((item, index) => {
            const date = new Date();
            const day = date.getDate(); 
            const month = date.getMonth();  
            const mymonth = monthNames[month];  
           
 
          return (
            <div className="item" key={index}>
              <div className="topperdiv birthdaybg">
                <div className="toppertaj">
                <img src={item.attachments && item.attachments[0] ? `https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}` : "https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/topper.jpg"} className="img-fluid" />
                </div>
                <div className="topperdesc">
                  <p className="name">{item.name} </p>
                  <p className="per">{day} {mymonth}</p>
                  <p>{item.class}</p>
                </div>
              </div>
            </div>
          )
          })
        ) : (
          emptyArray.map((item, index) => (
            <div className="item" key={index}>
              <div className="topperdiv birthdaybg">
                <div className="toppertaj">
                  <img src={item.attachments} className="img-fluid" alt="Default" />
                </div>
                <div className="topperdesc">
                  <p className="name">{item.name} </p>
                  <p className="per">{item.date}</p>
                  <p>{item.class}</p>
                </div>
              </div>
            </div>
          ))
        )}
      </Slider>
    </>
  );
};

export default Birthday;
