import React from 'react';
import OwlCarousel from 'react-owl-carousel';  
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Assets/kioskApp.css';
import Teacherbirthdays from '../Component/Teacherbirthdays'
import Acheivers from '../Component/Acheiver'
import Birthday from '../Component/StudentBirthdays';
import KioskGallery from '../Component/KioskGallery';
import KioskNews from '../Component/KioskNews';

const HomeKiosk = () => {
    var settings = {
        loop: true,
        margin: 0,
        responsiveClass: true,
        autoplay: true,
        dots: true,
        autoplayTimeout: 20000,
        items: 1,
    };
    return (
        <>
            <div className="slidescreen">
                <div className="demo">
                    <div className="item">
                        <OwlCarousel className='owl-theme' loop margin={10} {...settings}>
                            <li className="item">                               
                                <div class="slide1">
                                    <div class="slide_blk">
                                        <div class="logo-kiosk">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="Alternate Text" />
                                        </div>
                                        <div class="school_img">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" />
                                        </div>
                                        <div class="title d-flex"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/title-img1.png" alt="Alternate Text" />
                                            <h3>Welcome To <br></br><span>St. Joseph's High School, Mandi</span></h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/title-img2.png" alt="Alternate Text" />
                                        </div>
                                        <div class="content">
                                            <p>St. Joseph’s High School, Dabwali is run by the registered Society of Pilar, Punjab- Haryana, a branch of the Society of Pilar, Goa.The school is run by the religious minority in fulfillment of its constitution right under article 30 (1).This school is affiliated to the Council for the Indian School Certificate Examination, New Delhi. St. Joseph’s High School was opened in 1970 to cater to the pressing educational needs of Mandi Dabwali and the surroundings villages. The school aims at all round development of the child, moral along with the hope of drawing out the best in each, so as to enable its pupils to blossom into gems of persons and capable citizens of our country.</p>
                                        </div>
                                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/bandstrip.png" alt="Alternate Text" class="bandstrip" />
                                    </div>
                                </div>
                            </li>
                            <li className="item">
                                <div class="topper-blk wrapper">
                                    <div class="scrollbox">
                                        <div class="logo-kiosk">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="Alternate Text" />
                                        </div>
                                        <div class="title-bar">
                                            <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/trophy-1.png" alt="Alternate Text" /> School <b>Achievements</b></h3>
                                        </div>
                                        <Acheivers />
                                        {/* <div id="carouselExampleSlidesOnly" class="carousel slide gallery-blk" data-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <div class="d-flex">
                                                        <div class="gallery-block">
                                                            <div class="galleryImg">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/1.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="galleryContent">
                                                                <h3>SECOND POSITION</h3>
                                                            </div>
                                                        </div>
                                                        <div class="gallery-block">
                                                            <div class="galleryImg">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/2.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="galleryContent">
                                                                <h3>CONGRATULATIONS</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="carousel-item">
                                                    <div class="d-flex">
                                                        <div class="gallery-block">
                                                            <div class="galleryImg">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/3.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="galleryContent">
                                                                <h3>CONGRATULATIONS</h3>
                                                            </div>
                                                        </div>
                                                        <div class="gallery-block">
                                                            <div class="galleryImg">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/4.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="galleryContent">
                                                                <h3>CONGRATULATIONS</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="title-bar">
                                            <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/trophy-2.png" alt="Alternate Text" /> School <b>Activities</b></h3>
                                        </div>

                                        <KioskGallery />
                                        {/* <div id="carouselExampleSlidesOnly1" class="carousel slide" data-ride="carousel">
                                            <div class="carousel-inner">
                                                <div class="carousel-item active">
                                                    <div class="scrollgroup toppers">
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="carousel-item ">
                                                    <div class="scrollgroup toppers">
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                        <div class="scrollitem">
                                                            <div class="ach-img">
                                                                <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/boy.jpg" alt="Alternate Text" />
                                                            </div>
                                                            <div class="ach-cont">
                                                                <h3>Akash Sharma</h3>
                                                                <p><span>98%</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </li>
                            <li className="item">
                                <div class="birthday-blk">
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/birthday.gif" class="bth-gif" alt="Alternate Text" />
                                    <div class="birthdays-blk">
                                        <div class="title-bar">
                                            <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/birth.png" alt="Alternate Text" /> Student's <b>Birthday</b></h3>
                                        </div>

                                        <Birthday />
                                        <div class="lines">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/lines.png" alt="Alternate Text" />
                                        </div>

                                    </div>
                                    <div class="birthdays-blk">
                                        <div class="title-bar">
                                            <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/birth.png" alt="Alternate Text" /> Teacher's <b>Birthday</b></h3>
                                        </div>

                                        <Teacherbirthdays />
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="gallery-section">
                                    <div class="gallery-shade">
                                        <div class="gal-logo">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/logo.png" alt="Alternate Text" />
                                        </div>
                                        <div class="kiosk-gallery-blk">
                                            <div class="title-bar">
                                                <h3><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/gallery.png" alt="Alternate Text" /> Latest  <b>News</b></h3>
                                            </div>

                                        </div>
                                        <div className='news'>
                                        <marquee  direction="up"  >
                                                <KioskNews />
                                         </marquee>
                                        </div>
                                        <div class="lines">
                                            <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/lines.png" alt="Alternate Text" />
                                        </div>
                                         
                                    </div>
                                    <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/plant.png" class="img-plant" alt="Alternate Text" />
                                </div>
                            </li>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeKiosk;