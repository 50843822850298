import React, { useEffect, useState } from "react";
import { getNews } from "../Service/Api";
import { Link } from 'react-router-dom'; 

const News = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNews();
        setNewsData(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);
  const emptyArray = [
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" },
      { title: "Stay Tuned For More Updates", description: "Stay Tuned For More Updates" }
  ];
 
  return (
    <>
    <div className="newsbg">
    <div className='title'> 
        <div>
        <p>NOTICE</p>
        <h2>Latest news</h2>
        </div>
      
    <Link to="/News"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/SJHSMD/public/Images/btn.png"/></Link> 
    </div>
    <marquee onMouseOver={(event) => event.currentTarget.stop()} direction="up" onMouseOut={(event) => event.currentTarget.start()}>
      <ul className="newsboxlist">
      {newsData.length > 0 ?  newsData?.filter((item) =>  item.category === "News").map((item, index) => (   
         <li key={index}>
            <div className="newsbox">
              <div className="newsdesc"> 
                <p>{item.description}</p>
              </div>
              {item.attachments && item.attachments[0] && ( 
                <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link> 
              )}  
            </div>
          </li>
          )) : 
      emptyArray.map((data, index) => (
         <li key={index}>
            <div className="newsbox">
              <div className="newsdesc"> 
              <p>{data.description}</p>
              </div>
              <Link to="#" target="_blank"><i className="bi bi-paperclip"></i></Link>
            </div>
          </li>
          ))
        }
      </ul>
    </marquee>
  </div>
    </>
  );
};

export default News;
